import React from "react"
import Layout from "../components/layout"
import ProjectContent from "../components/ProjectContent"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import '../assets/less/projectpage.less'

export default ({ data }) => {
  // console.log(data)
  const post = data.allWordpressWpProjects.edges[0].node

  let metaDataTitle = ``
  let metaDataDescription = ``
  let metaDataImg = ``

  if(post) {
      metaDataTitle = post.acf.metadata_title ? post.acf.metadata_title : "Axel Olson"
      metaDataDescription = post.acf.metadata_description ? post.acf.metadata_description : "Architect."
      metaDataImg = post.acf.metadata_image ? post.acf.metadata_image.localFile.url : ''
  }

  return (
    <Layout page="What">
      <SEO title={metaDataTitle}
           description={metaDataDescription}
           imageURL={metaDataImg} />
      <ProjectContent project={post} />
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWordpressWpProjects(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          id
          acf {
            location_name
            description
            project_page_additional_details
            project_page_top_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            location_latitude
            location_longitude
            homepage_position_x
            homepage_position_y
            project_image {
              source_url
              localFile {
                childImageSharp {
                  fixed(webpQuality: 10, height: 800) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            project_image_hover {
              source_url
              localFile {
                childImageSharp {
                  fixed(webpQuality: 10, width: 500) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            metadata_title
            metadata_description
            metadata_image {
              localFile {
                url
              }
            }
          }
          content
          slug
        }
      }
    }
  }
`
