import React, { useState } from "react"
import Img from "gatsby-image"
import Modal from 'react-modal';
// import { useDispatch } from 'react-redux'

export default (props) => {
  const project = props.project

  return (
    <div>
      <div className="post-hero">
        <div className="post-image-wrapper">
          <div className={"post-image "}>
            <Img className={"project-landing-image " + project.id}
                 fluid={project.acf.project_page_top_image.localFile.childImageSharp.fluid}
                 alt={project.title} />
          </div>
        </div>
        <div className="post-info">
          <h1 dangerouslySetInnerHTML={{__html: project.title}}></h1>
        </div>
      </div>
      <div className="post-text">
        <div className="post-info-not-title" dangerouslySetInnerHTML={{__html: project.acf.project_page_additional_details}} />
        <div className="post-info">
        </div>
        <div className="post-body" dangerouslySetInnerHTML={{ __html: project.content }}>
        </div>
      </div>
    </div>
  )
}
